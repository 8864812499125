import type { ChangeEvent } from 'react';
import { Select } from '@fabric-ds/react';

interface Props {
    numberOfBeds: string | null;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    numberOfGuests: string;
    noDefaultValue?: boolean;
}

export const CalendarSelectGuests = ({ numberOfBeds, onChange, numberOfGuests, noDefaultValue = true }: Props) => {
    const maxNoOfGuests = numberOfBeds ? Number(numberOfBeds) : 10;
    const guestOptions = Array.from({ length: maxNoOfGuests }, (_, i) => {
        const option = i + 1;
        return (
            <option key={option} value={option}>
                {option}
            </option>
        );
    });

    return (
        <Select label={'Antall gjester'} onChange={onChange} value={numberOfGuests} className="md:pb-4">
            {noDefaultValue && <option value={'Velg antall'}>Velg antall</option>}
            {guestOptions}
        </Select>
    );
};
